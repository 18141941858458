<template>
  <div class="regard">
    <div class="head-public animate__animated animate__fadeIn">
      <img src="@/assets/img/banner1.png" alt="" />
    </div>

    <!-- 优势 -->

    <div class="advantage">
      <div class="title-public">
        <p>Franchise advantages</p>
        <h3>
          <span class="line-l"></span>
          <span class="center">公司优势</span>
          <span class="line-r"></span>
        </h3>
      </div>
      <div class="content">
        <ul class="tex-l">
          <li>
            <span class="number">01</span>
            <b>品牌积淀</b>
            <div class="des">30余载品牌历程，深度服务数千万中国家庭</div>
          </li>
          <li>
            <span class="number">02</span>
            <b>绿色荣誉</b>
            <div class="des">
              荣获“中国品牌价值500强企业”、“中国环境标志产品认证证书”、“中国名牌产品”等2000+项殊荣
            </div>
          </li>
          <li>
            <span class="number">03</span>
            <b>超全品类</b>
            <div class="des">
              品类产品布局，涵盖全屋定制、成品家具、沙发、整体橱柜、卫浴、窗帘、多功能墙面家居、软装配套等
            </div>
          </li>
          <li>
            <span class="number">04</span>
            <b>渠道网络</b>
            <div class="des">3000余家专卖店，贴心服务终端用户</div>
          </li>
          <li>
            <span class="number">05</span>
            <b>智能制造</b>
            <div class="des">
              国际智能先进生产设备集群，实现大规模定制化的智能生产
            </div>
          </li>
        </ul>
        <div class="center">
          <div class="over">
            <img src="@/assets/img/scene.jpg" alt="" />
          </div>
          <div class="dot-box">
            <i class="dot l"></i>
            <i class="dot l"></i>
            <i class="dot l"></i>
            <i class="dot l"></i>
            <i class="dot l"></i>
            <i class="dot r"></i>
            <i class="dot r"></i>
            <i class="dot r"></i>
            <i class="dot r"></i>
            <i class="dot r"></i>
          </div>
        </div>
        <ul class="tex-r">
          <li>
            <span class="number">06</span>
            <b>严苛工艺</b>
            <div class="des">30余载品牌历程，深度服务数千万中国家庭</div>
          </li>
          <li>
            <span class="number">07</span>
            <b>高效物流</b>
            <div class="des">
              荣获“中国品牌价值500强企业”、“中国环境标志产品认证证书”、“中国名牌产品”等2000+项殊荣
            </div>
          </li>
          <li>
            <span class="number">08</span>
            <b>媒体资源</b>
            <div class="des">
              品类产品布局，涵盖全屋定制、成品家具、沙发、整体橱柜、卫浴、窗帘、多功能墙面家居、软装配套等
            </div>
          </li>
          <li>
            <span class="number">09</span>
            <b>运营保障</b>
            <div class="des">3000余家专卖店，贴心服务终端用户</div>
          </li>
          <li>
            <span class="number">10</span>
            <b>产品畅销全国</b>
            <div class="des">
              国际智能先进生产设备集群，实现大规模定制化的智能生产
            </div>
          </li>
        </ul>
      </div>
    </div>

    <!-- 产品实力 -->
    <!-- <div class="product">
        <div class="title">
        <p>Product strength</p>
        <h3>
          <span class="line-l"></span>
          <span class="center">产品实力</span>
          <span class="line-r"></span>
        </h3>
      </div>
    </div> -->
    <Regards></Regards>
  </div>
</template>
    
<script>
import Regards from "@/components/Pc/regards.vue";
export default {
  name: "regard",
  components: {
    Regards: Regards,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
    
<style scoped>
.regard {
  min-height: 100vh;
}
.advantage {
  padding: 70px 0;
  width: 1400px;
  margin: 0 auto;
}
.content {
  width: 100%;
  height: auto;
  overflow: hidden;
  position: relative;
  padding: 50px 3%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}
.content ul {
  width: 31%;
  position: relative;
  z-index: 5;
}
.content .tex-l {
  text-align: right;
}
.content .center {
  width: 34vw;
  height: 34vw;
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.content .center::before {
  border-radius: 50%;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  border: 1px solid #ebebeb;
  width: 100%;
  height: 100%;
}
.content ul li {
  width: 100%;
  height: auto;
  position: relative;
  margin: 45px 0;
}
.content .tex-l li:nth-child(1),
.content .tex-l li:nth-child(5) {
  margin-left: -5%;
}
.content .tex-l li:nth-child(2),
.content .tex-l li:nth-child(4) {
  margin-left: -25%;
}
.content .tex-l li:nth-child(3) {
  margin-left: -32%;
}
.content .tex-r li:nth-child(1),
.content .tex-r li:nth-child(5) {
  margin-left: 5%;
}
.content .tex-r li:nth-child(2),
.content .tex-r li:nth-child(4) {
  margin-left: 25%;
}
.content .tex-r li:nth-child(3) {
  margin-left: 32%;
}
.content .tex-l .number {
  right: 0;
}
.content .tex-r .number {
  left: 0;
}
.content ul li b {
  display: block;
  font-size: 18px;
  line-height: 35px;
  font-family: wygt;
  position: relative;
  z-index: 5;
}
.content ul li .number {
  position: absolute;
  font-size: 54px;
  color: #f5f5f5;
  z-index: 1;
  top: -50%;
}
.content ul li .des {
  width: 85%;
  line-height: 25px;
  opacity: 0.7;
  display: inline-block;
  min-height: 50px;
}
.content .over {
  position: absolute;
  top: 50%;
  height: 85%;
  width: 85%;
  transform: translate(-50%, -50%);
  left: 50%;
  border-radius: 50%;
  border: 20px solid #effcf9;
  z-index: 5;
  overflow: hidden;
}
.content .over img {
  display: block;
  /* transform: scale(1.1); */
  /* width: 100%; */
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1.1);
  /* z-index: 0; */
}
.content .dot {
  width: 15px;
  height: 15px;
  background: #2fc7a0;
  position: absolute;
  border-radius: 50%;
  top: 50%;
  z-index: 6;
}
.content .dot.l {
  transform: translate(-50%, -50%);
}
.content .dot.r {
  transform: translate(50%, -50%);
}
.content .dot.l:nth-child(1),
.content .dot.r:nth-child(6) {
  top: 13%;
}
.content .dot.l:nth-child(3),
.content .dot.r:nth-child(8) {
  top: 50%;
}
.content .dot.l:nth-child(4),
.content .dot.r:nth-child(9) {
  top: 71%;
}
.content .dot.l:nth-child(5),
.content .dot.r:nth-child(10) {
  top: 87%;
}
.content .dot:nth-child(1),
.content .dot:nth-child(5) {
  left: 16.5%;
}
.content .dot:nth-child(10),
.content .dot:nth-child(6) {
  right: 16.5%;
}
.content .dot:nth-child(3) {
  left: 0;
}
.content .dot:nth-child(8) {
  right: 0;
}
.content .dot:nth-child(2),
.content .dot:nth-child(4) {
  left: 4.5%;
}
.content .dot:nth-child(2),
.content .dot:nth-child(7) {
  top: 29%;
}
.content .dot:nth-child(7),
.content .dot:nth-child(9) {
  right: 4.5%;
}
</style>