<template>
  <div class="regards">
    <!-- <Swiper></Swiper> -->
    <div class="cnt1">
      <img src="@/assets/img/swiper3.jpg" alt="" />
      <div :class="textTop <= 0 ? 'show text' : 'text'" ref="text">
        <h2>公司全面推进企业信息化战略</h2>
        <p>
          实现了从产品研发设计、采购、生产制造、物流、销售，到顾客售后服务的全价值链信息化集成管理，从而整合产业上、中、下游资源，为顾客提供卓越的产品和服务价值。
        </p>
      </div>
    </div>
    <dir class="cn2">
      <div class="left">
        <img
          :class="leftImg1Top <= 0 ? 'show left-img1' : 'left-img1'"
          src="@/assets/img/page3-left1.jpg"
          alt=""
          ref="leftImg1"
        />
        <div
          :class="leftCntTop <= 0 ? 'show left-cnt' : 'left-cnt'"
          ref="leftCnt"
        >
          公司建立了质量、环境、职业健康安全管理三大体系和能源管理体系，多次荣获“绿色设计国际贡献奖”、“全国产品和服务质量诚信示范企业”、“全国质量检验稳定合格产品”，先后荣膺“绿色先锋企业”、“绿色领跑企业”、“杰出质造企业”、“绿色供应链管理示范企业”、“全国售后服务十佳单位”、“工业产品绿色设计示范企业”等殊荣，六大类产品通过中国绿色产品认证。
        </div>
        <img
          :class="leftImg2Top <= 0 ? 'show left-img2' : 'left-img2'"
          src="@/assets/img/page3-left1.png"
          alt=""
          ref="leftImg2"
        />
      </div>
      <div class="right">
        <img
          :class="rightImg1Top <= 0 ? 'show right-img1' : 'right-img1'"
          src="@/assets/img/page3-right.png"
          alt=""
          ref="rightImg1"
        />
        <div
          :class="rightCntTop <= 0 ? 'show right-cnt' : 'right-cnt'"
          ref="rightCnt"
        >
          <b>公司一直秉承绿色的核心理念</b>
          <p>
            以“鱼水文化、绿色文化”为企业核心文化理念。在全友，绿色不仅是全友品牌的基础色调和核心基因，更是全友人的价值追求和经营信条；绿色不仅是对产品全流程品质的精心打造，更是对用户、员工、经销商、供应商和社会的责任与担当。
          </p>
          <p>
            在绿色思考中坚定未来，在绿色行动中谱写美好！绿色全友，温馨世界！
          </p>
        </div>
      </div>
    </dir>
  </div>
</template>

<script>
// import Swiper from "./swiper.vue";
export default {
  name: "",
  // components: {
  //   Swiper: Swiper,
  // },
  data() {
    return {
      textTop: 0,
      leftImg1Top: 0,
      leftImg2Top: 0,
      leftCntTop: 0,
      rightImg1Top: 0,
      rightCntTop: 0,
    };
  },
  methods: {},
  created() {},
  mounted() {
    // console.log(this.$refs.leftCnt);
    this.$store.state.bar.addEventListener("scroll", () => {
      if (this.$refs.text) {
        this.textTop >= 0
          ? (this.textTop =
              this.$refs.text.getBoundingClientRect().y -
              window.innerHeight +
              this.$refs.text.clientHeight / 2)
          : -1;
        this.leftImg1Top >= 0
          ? (this.leftImg1Top =
              this.$refs.leftImg1.getBoundingClientRect().y -
              window.innerHeight +
              this.$refs.leftImg1.clientHeight / 2)
          : -1;
        this.leftImg2Top >= 0
          ? (this.leftImg2Top =
              this.$refs.leftImg2.getBoundingClientRect().y -
              window.innerHeight +
              this.$refs.leftImg2.clientHeight / 2)
          : -1;
        this.leftCntTop >= 0
          ? (this.leftCntTop =
              this.$refs.leftCnt.getBoundingClientRect().y -
              window.innerHeight +
              this.$refs.leftCnt.clientHeight / 2)
          : -1;
        this.rightImg1Top >= 0
          ? (this.rightImg1Top =
              this.$refs.rightImg1.getBoundingClientRect().y -
              window.innerHeight +
              this.$refs.rightImg1.clientHeight / 2)
          : -1;
        this.rightCntTop >= 0
          ? (this.rightCntTop =
              this.$refs.rightCnt.getBoundingClientRect().y -
              window.innerHeight +
              this.$refs.rightCnt.clientHeight / 2)
          : -1;
      }
    });
  },
};
</script>

<style scoped>
.regards {
  /* min-height: 2500px; */
  position: relative;
  z-index: 50;
  padding: 150px 0 0;
  box-sizing: border-box;
}
.cnt1 {
  position: relative;
}
.cnt1 img {
  width: 100%;
  height: 70vh;
  opacity: 0.5;
}
.cnt1 .text {
  color: #fff;
  line-height: 2;
  font-size: 18px;
  width: 25%;
  position: absolute;
  left: 55%;
  bottom: 10%;
}
.cn2 {
  width: 68.5%;
  /* max-width: 1300px; */
  margin: 0 auto;
  margin-top: 0px;
  /* position: relative; */
  padding: 0px 0px;
  margin-top: -25vh;
  display: flex;
}
.left,
.right {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.left img,
.right img {
  width: 100%;
  display: block;
}
.left {
  width: 50%;
}
.left-cnt {
  width: 100%;
  padding: 100px 10%;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 2;
  background: #fff;
  color: #878787;
  height: 60%;
  /* height: 29%; */
}
.right img {
  margin-top: 58%;
}
.right-cnt {
  background: #fff;
  color: #878787;
  padding: 100px 10%;
  height: 100%;
  line-height: 2;
  /* height: 21.2%; */
}
.right-cnt > b {
  display: block;
  font-size: 24px;
  line-height: 2;
  width: 100%;
  font-weight: normal;
  color: #0bb78e;
  margin-bottom: 1vw;
}
.text,
.left-img1,
.left-cnt,
.left-img2,
.right-cnt,
.right-img1 {
  visibility: hidden;
  position: relative;
  z-index: 1;
}
.show {
  visibility: visible !important;
  animation: zoom 1s linear 1 !important;
}
@keyframes zoom {
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}
</style>
